<template>
    <div class="page-header">
        <div class="page-header-content">
            <img src="../assets/banner.png"/>
            <div class="header-search-content">
                <a-input-search ref="userNameInput" placeholder="全站关键词搜索" @search="search" enter-button="搜索" allowClear v-if="this.$route.name != 'search_list'">
                    <a-icon slot="prefix" type="search"/>
                </a-input-search>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Header",
        data() {
            return {}
        },
        methods: {
            search(val) {
                if (this.$route.name != 'search_list' && val) {
                    this.$router.push({name: 'search_list', query: { keywords: val || '' }})
                }
            }
        },
        created() {
            console.log(this.$route.name)
        }
    }
</script>

<style scoped lang="scss">
    .page-header {
        .page-header-content {
            position: relative;
            height: 480px;
            &>img{
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
            }
            .header-search-content {
                position: absolute;
                width: 250px;
                height: 60px;
                left: calc(50% + 300px);
                top: 40px;
                /deep/ .anticon-search{
                    color: #fff;
                }
                /deep/ .ant-input-clear-icon{
                    color: #fff;
                }
               /deep/ .ant-input{
                   /*background: rgba(255,255,255,0.2);*/
                   background: rgba(120, 120, 120, 0.2);
                   color: #fff;
                   /*color: #333;*/
                   border-color: #2a8ad3;
                }
                /deep/ .ant-input::-webkit-input-placeholder {
                    color: white;
                }
            }
        }
    }
</style>