import Vue from "vue";
import VueRouter from "vue-router";
// import Home from "../views/Home.vue";
import BasicLayout from "../components/BasicLayout";


Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: BasicLayout,
    children:[
      {
        path: "/",
        name: "pageHome",
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/Index.vue"),
      },
      {
        path: "/kpzsb_list",
        name: "kpzsb_list",
        meta: {
          keepAlive: true,
          isBack: false
        },
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/list/KpzsbList.vue"),
      },
      {
        path: "/kpzsb_detail",
        name: "kpzsb_detail",
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/detail/KpzsbDetail.vue"),
      },
      {
        path: "/kpwsp_list",
        name: "kpwsp_list",
        meta: {
          keepAlive: true,
          isBack: false
        },
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/list/KpwspList.vue"),
      },
      {
        path: "/kpwsp_detail",
        name: "kpwsp_detail",
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/detail/KpwspDetail.vue"),
      },
      {
        path: "/kpzsk_list",
        name: "kpzsk_list",
        meta: {
          keepAlive: true,
          isBack: false
        },
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/list/KpzskList.vue"),
      },
      {
        path: "/kpzsk_detail",
        name: "kpzsk_detail",
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/detail/KpzskDetail.vue"),
      },
      {
        path: "/kpydh_list",
        name: "kpydh_list",
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/list/KpydhList.vue"),
      },
      {
        path: "/kpydh_detail",
        name: "kpydh_detail",
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/detail/KpydhDetail.vue"),
      },
      {
        path: "/kpjdx_list",
        name: "kpjdx_list",
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/list/KpjdxList.vue"),
      },
      {
        path: "/kpjdx_detail",
        name: "kpjdx_detail",
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/detail/KpjdxDetail.vue"),
      },
      {
        path: "/kpzbj_list",
        name: "kpzbj_list",
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/list/KpzbjList.vue"),
      },
      {
        path: "/search_list",
        name: "search_list",
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/list/SearchList.vue"),
      },
      {
        path: "/sh_detail",
        name: "sh_detail",
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/detail/SHDetail.vue"),
      },

    ]
  },
  {
    path: "/new_list",
    name: "new_list",
    component: () =>
        import(/* webpackChunkName: "about" */ "../views/list/NewList.vue"),
  },
  {
    path: "/new_detail",
    name: "new_detail",
    component: () =>
        import(/* webpackChunkName: "about" */ "../views/detail/NewDetail.vue"),
  },
  // {
  //   path: "/list",
  //   name: "List",
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/List.vue"),
  // },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
