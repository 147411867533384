import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import antd from 'ant-design-vue'
import 'ant-design-vue/dist/antd.css';
import {
  Button
} from 'ant-design-vue'
Vue.use(Button);
Vue.use(antd);
Vue.config.productionTip = false;

router.afterEach(() => {
  document.body.scrollTop = 0;
  window.scrollTo(0, 0);
});
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
