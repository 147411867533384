<template>
    <div class="basic-layout">
        <Header></Header>
        <keep-alive>
            <router-view v-if="$route.meta && $route.meta.keepAlive" />
        </keep-alive>
        <router-view v-if="!$route.meta.keepAlive" />
        <Footer></Footer>
    </div>
</template>

<script>
    import Header from './Header'
    import Footer from './Footer'

    export default {
        name: "BasicLayout",
        components: {
            Header,
            Footer
        },
        data() {
            return {}
        },
        methods: {}
    }
</script>

<style scoped lang="scss">

</style>